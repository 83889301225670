import { type ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';

@Component({
  imports: [],
  selector: 'sds-kpi-header-renderer',
  template: `
    <span [attr.data-userlane]="'kpi-header-' + dataUserlaneValue">
      {{ params?.displayName }}
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KPIHeaderRendererComponent implements ICellRendererAngularComp {
  params: any;
  headerName: string;
  dataUserlaneValue: string;
  private readonly cd = inject(ChangeDetectorRef);

  agInit(params: any): void {
    this.params = params;
    this.dataUserlaneValue = params?.displayName?.replace(/\s+/g, '-').toLowerCase();
    this.cd.markForCheck();
  }

  refresh(): boolean {
    return false;
  }
}
