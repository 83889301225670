import { type ICellRendererAngularComp } from '@ag-grid-community/angular';
import { type ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, inject, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@sds/shared';

@Component({
  imports: [SharedModule],
  selector: 'sds-performance-ratio-cell',
  template: `
    @if (value) {
      <span>{{ value }}</span>
    } @else {
      <div class="flex items-center">
        <span>{{ 'shared.notAvailable' | translate }}</span>
        <mat-icon matTooltipClass="whitespace-pre-line" [matTooltip]="message">info</mat-icon>
      </div>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PerformanceRatioCellRendererComponent implements ICellRendererAngularComp {
  value?: number;
  translateService = inject(TranslateService);
  sanitizer = inject(DomSanitizer);
  // need to sanitize it twice to get rid of the error message 'Safe value must use [property]=binding'
  // https://stackoverflow.com/a/53912327
  message = this.sanitizer.sanitize(
    SecurityContext.HTML,
    this.sanitizer.bypassSecurityTrustHtml(
      this.translateService.instant('chartShared.noDataWeather').replaceAll('</br>', '\n')
    )
  );
  agInit(params: ICellRendererParams): void {
    if (params.value !== '') {
      this.value = params.value;
    }
  }
  refresh(): boolean {
    return false;
  }
}
