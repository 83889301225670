<mat-card class="h-full w-full" data-userlane="kpis-list-table">
  <sds-widget-header-buttons [hasMenu]="true" [hasFullscreen]="false">
    <button mat-menu-item type="button" (click)="csvExport()">
      <mat-icon svgIcon="sl:csv"></mat-icon>
      <span>CSV</span>
    </button>
  </sds-widget-header-buttons>

  <mat-card-content>
    <ag-grid-angular
      class="ag-theme-material visible-cell-borders visible-column-hover"
      [gridOptions]="gridOptions"
      [modules]="gridModules"
      [cacheBlockSize]="cacheBlockSize"
    ></ag-grid-angular>
  </mat-card-content>
</mat-card>
